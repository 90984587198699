<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewSymptoms"
          >
            New Sub Group
          </button>
        </div>

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <!-- <template #userType-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('userType', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>
            <option value="1"> Doctor </option>
            <option value="2">Patient</option>
          </select>
        </template> -->
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <!-- <template #userType="{item}">
        <td
          v-if="item.userType == 1">
            Doctor  </td>
          <td v-else>No </td>
       
        
      </template>   -->
          <template #show_details="{ item }">
            <td class="py-2">
              <!-- <div class="btn-group dropup"> -->
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'Sub Group',
                    params: {
                      subGroupId: item.subGroupId,
                      groupId: item.groupId,
                      mode: 'view',
                    },
                  }"
                  >View
                </CDropdownItem>
                <div v-if="item.groupId == 1">
                  <CDropdownItem
                    :to="{
                      name: 'Models',
                      params: {
                        subGroupId: item.subGroupId,
                        groupId: item.groupId,
                      },
                    }"
                    >Equipments</CDropdownItem
                  >
                </div>
                <CDropdownItem
                  :to="{
                    name: 'Departments',
                    params: {
                      subGroupId: item.subGroupId,
                      groupId: item.groupId,
                    },
                  }"
                  >Departments</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'SWPs',
                    params: {
                      subGroupId: item.subGroupId,
                      groupId: item.groupId,
                    },
                  }"
                  >RA/SWP</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'BriefNames',
                    params: {
                      subGroupId: item.subGroupId,
                      groupId: item.groupId,
                    },
                  }"
                  >Brief Names</CDropdownItem
                >
                <!-- <div v-if="item.subGroupId == 1 && item.subGroupId == 2"> -->
                <!-- <CDropdownItem @click="popupActive = true"
                    >Merge Users</CDropdownItem
                  > -->

                <div v-if="item.subGroupId == 1 || item.subGroupId == 2">
                  <mergeUsers>
                    <p v-if="item.subGroupName == 'QC'">
                      <span class="Merge Users">
                        Are you sure you want to Merge YC PPT Department Users
                        ?</span
                      >
                    </p>

                    <p></p>
                    <p v-if="item.subGroupName == 'YC'">
                      <span class="Merge Users">
                        Are you sure you want to Merge QC PPT Department Users
                        ?</span
                      >
                    </p>

                    <p>
                      <!-- <div class="col-sm-9"> -->

                      <select
                        class="form-control"
                        v-model="item.mergeDepartment"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <br />

                      <!-- </div> -->

                      <!-- </p> -->

                      <!-- <span class="col-md-2" v-if="item.mergeDepartment == true">
                      <input id="yes" type="checkbox" class="mr-2" checked/> Yes
                       <input id="no" type="checkbox" class="mr-2"  @click="uncheckNo(item.mergeDepartment)" /> No
                    </span> -->
                      <!-- <span class="col-md-2" v-else>
                      <input
                      id = "yes2"
                        type="checkbox"
                        class="mr-2"
                        @click="uncheckNo(item.mergeDepartment)"
                      />Yes
                    </span> -->

                      <!-- <span class="col-md-2" v-if="item.mergeDepartment == false">
                       <input id="yes" type="checkbox" class="mr-2"  @click="uncheckYes(item.mergeDepartment)" /> Yes
                      <input id="no" type="checkbox" class="mr-2" checked />No
                    </span> -->

                      <!-- <span class="col-md-2" v-else>
                      <input id = "no2"
                        type="checkbox"
                        class="mr-2"
                       
                      />No
                    </span> -->
                    </p>

                    <div>
                      <br />
                    </div>
                    <div align="right">
                      <CButton
                        class="mr-2"
                        type="submit"
                        size="sm"
                        color="primary"
                        @click="
                          mergeDepartment(item.subGroupId, item.mergeDepartment)
                        "
                        >Save</CButton
                      >
                      <CButton
                        class="mr-2"
                        type="Cancel"
                        size="sm"
                        color="danger"
                        @click="cancel()"
                        >Cancel</CButton
                      >
                    </div>
                  </mergeUsers>
                </div>
                <!-- </div> -->
                <!-- <mergeQC @click="action">Merge</mergeQC> -->
                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteGroup(item.subGroupId)"
                ></ConfirmationModal>
              </CDropdown>
              <!-- </div> -->
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>

      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>



<script >
const fields = [
  "index",
  {
    key: "subGroupName",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
import mergeUsers from "../../containers/mergeUsers.vue";

export default {
  name: "Sub Groups",
  components: {
    ConfirmationModal,
    mergeUsers,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
      //this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/sub/groups/"+ this.$route.params.groupId)
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/sub/groups/" +
          this.$route.params.groupId
      )
        .then((response) => response.json())
        .then(
          (data) => (this.items = data),
          console.log("sub group values", this.items)
        );
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    deleteGroup(subGroupId) {
      console.log("delete called with", this.subGroupId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/subgroup/" +
          subGroupId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },
    mergeDepartment(subGroupId, mergeDepartment) {
      console.log("merge called with", subGroupId, mergeDepartment);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/merge/subGroup/" +
          subGroupId +
          "?mergeDepartment=" +
          mergeDepartment
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("merge department", data);

          window.location.reload();
        });
    },

    cancel() {
      window.history.back();
    },
    navToNewSymptoms() {
      this.$router.push({
        name: "Sub Group",
        params: { groupId: this.$route.params.groupId, mode: "new" },
      });
    },
  },
  mounted() {
    this.getDepartments();
  },
};
</script>

<style scoped>
.merge {
  font-size: 18px;
}
</style>